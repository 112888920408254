/*------------------------------------------------------------------------
 # author    Eric Worman, HotRocket Development, Inc.
 # copyright Copyright  2014. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website    Hodges Biz Services
 # Path: /resources/assets/js/common.js
 -------------------------------------------------------------------------*/
//jQuery document initialization
//$(document).ready(function() { });


//jQuery window resize listener
$(window).resize(function() {

    //Admin function to reformat the field labels for either 2 lines high or one
    //formatFieldLabels();
});


//jQuery Scroll Listener
//$(window).scroll(function() { });
